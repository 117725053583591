import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName 'home' */ '../views/Top.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: 'about' */ '../views/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: 'contact' */ '../views/Contact.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: 'terms' */ '../views/Terms.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: 'privacypolicy' */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/concierges',
    name: 'Concierges',
    props: true,
    component: () => import(/* webpackChunkName: 'allconcierges' */ '../views/concierges/Concierges.vue')
  },
  {
    path: '/flow/item-suggestions',
    name: 'ItemSugesstions',
    component: () => import(/* webpackChunkName: 'flowitemsuggestion' */ '../views/flow/FlowItemSuggestions.vue')
  },
  {
    path: '/flow/skin-type-diag',
    name: 'SkinTypeDiag',
    component: () => import(/* webpackChunkName: 'skintypediag' */ '../views/flow/FlowSkinTypeDiag.vue')
  },
  {
    path: '/result/skin-type-diag',
    name: 'SkinTypeResult',
    props: true,
    component: () => import(/* webpackChunkName: 'skintyperesult' */ '../views/result/SkinTypeResult.vue')
  },
  {
    path: '/result/item-suggestions/skincare',
    name: 'SkincareItemResult',
    props: true,
    component: () => import(/* webpackChunkName: 'skincareresult' */ '../views/result/SkincareItemResult.vue')
  },
  {
    path: '/result/item-suggestions/makeup',
    name: 'MakeupItemResult',
    props: true,
    component: () => import(/* webpackChunkName: 'makeupresult' */ '../views/result/MakeupItemResult.vue')
  },
  {
    path: '/mykarte',
    name: 'Mykarte',
    component: () => import(/* webpackChunkName: 'mydata' */ '../views/Mykarte.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    // return desired position
    return { top: 0 }
  },
})

export default router
