<template>
  <div>
    <global-header
      :isLiffOpen="isLiffOpen"
      class="site-header"
    />
    <router-view 
      class="main"
      :isLiffOpen="isLiffOpen"
      :id_token="line_id_token"
    />
    <global-footer />
  </div>
</template>
<script>
import liff from '@line/liff'
import GlobalHeader from './components/GlobalHeader.vue'
import GlobalFooter from './components/GlobalFooter.vue'

export default {
  name: 'App',
  components: {
    GlobalHeader,
    GlobalFooter
  },
  data() {
    return {
      line_id_token: '',
      isLiffOpen: false,
    }
  },
  created(){
    this.isLiffOpen = liff.isInClient()
    liff
      .init({ liffId: process.env.VUE_APP_LIFF_ID })
      .then(() => {
        this.line_id_token = liff.getIDToken()
      })
      .catch((err) => {
        // Error happens during initialization
        console.log('App',err.code, err.message);
      });
  }
}
</script>
<style>
@import "../public/resources/reset.css";
#app {
  font-family: "YuGothic";
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  color: #707070;
}
* ::before ::after{
  border-style: solid;
  border-width: 0;
}
.main{
  padding-top: 70px;
}
.site-header {
  position: fixed;
}
</style>
