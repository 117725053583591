<template>
  <footer>
    <div class="footer_contents">
      <a
        class="footer_logo"
        href="#"
      >
        <img src="@/assets/logos/logo_wide.png">
      </a>
      <a
        class="instagram_btn"
        href="https://www.instagram.com/yuni_cosme"
      >
        <img src="@/assets/logos/instagram_logo.svg">
      </a>

      <div class="footer_links">
        <div class="footer_links_firstLine">
          <router-link
            to="/about"
          >
            ABOUT
          </router-link>
          <a href="https://justfit.xyz/">
            COMPANY
          </a>
        </div>
        <div class="footer_links_secondLine">
          <router-link
            to="/contact"
          >
            CONTACT
          </router-link>
          <router-link
            to="/terms"
          >
            TERMS
          </router-link>
          <router-link
            to="/privacy-policy"
          >
            PRIVACY&nbsp;POLICY
          </router-link>
        </div>
      </div>
      <p class="footer_copyright">
        ©&nbsp;2017&nbsp;-
        {{ year }}
        &nbsp;JustFit&nbsp;inc,&nbsp;All&nbsp;rights&nbsp;reserved.
      </p>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'GlobalFooter',
  data() {
    return {
      year: new Date().getUTCFullYear(),
    }
  },
};
</script>
<style scoped>
footer {
    width: 100%;
    background: #F5EAE7;
    font-weight: bold;
    font-family: "Brandon Grotesque";
}
.footer_contents {
    padding: 100px 0px;
    text-align: center;
    width: 260px;
    margin: 0 auto;
}
a {
    background-color: transparent;
    text-decoration: none;
    color: inherit;
}
img, embed, object, iframe {
    vertical-align: bottom;
}
.footer_logo img {
    width: 147px;
}
.instagram_btn {
    display: block;
    text-align: center;
    padding: 20px 0 40px 0;
}
.instagram_btn img {
    width: 36px;
    height: 36px;
}
.footer_links {
    font-size: 10px;
    height: 50px;
}
.footer_links_firstLine {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}
.footer_links_firstLine a {
    padding: 0 10px;
}
.footer_links_secondLine {
    display: flex;
    justify-content: center;
    /* bottom: 0px; */
}
.footer_links_secondLine a {
    padding: 0 10px;
}
.footer_copyright {
    padding-top: 20px;
    font-size: 10px;
}
</style>
