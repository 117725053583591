import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";


const app = createApp(App);
app.use(router);
app.use(VueGtag, {
  pageTrackerTemplate(to) {
    return {
      page_title: to.name === "Mykarte" ? "Mykarte-SkinTypeDiag" : to.name,
      page_path: to.path
    }
  },
  config: { id: process.env.VUE_APP_GA_ID },
},
  router
);

app.mount('#app')
